<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="12">
            <iq-card body-class="p-0">
              <template v-slot:body>
                <div class="iq-edit-list">
                  <tab-nav :pills="true" class="iq-edit-profile d-flex">
                    <tab-nav-items class="col-md-3 p-0" :active="true" href="#personal-information" :title="$t('customer.personal_info')" />
                  </tab-nav>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="12">
            <div class="iq-edit-list-data">
              <tab-content id="pills-tabContent-2">
                <tab-content-item :active="true" id="personal-information" >
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('employee.personal_info') }}</h4>
                    </template>
                    <template v-slot:body>
                      <b-form @submit.prevent="savePersonalInformation(user)">
                        <b-form-group class="col-md-6 col-sm-12 align-items-center">
                          <div class="profile-img-edit">
                            <b-img :src="user.logo" class="height-150" alt="profile-pic"/>
                            <input type="hidden" v-model="user.logo">
                            <div class="p-image">
                              <div class="position-relative">
                                <i class="ri-pencil-line upload-button">
                                  <input type="file" ref="file" @change="previewImage(user)" class="h-100 position-absolute" accept="image/*" style="opacity: 0; left: 0" />
                                </i>
                              </div>
                            </div>
                          </div>
                        </b-form-group>
                        <b-row align-v="center">
                          <b-form-group class="col-md-6" :label="$t('customer.customer_name')" label-for="name">
                            <ValidationProvider name="Customer name" rules="required" v-slot="{ errors }">
                              <b-form-input v-model="user.name" type="text" :placeholder="$t('customer.customer_name')" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                              <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                          <b-form-group class="col-sm-6">
                            <label for="contact_no">{{ $t('customer.contact_no') }}:</label>
                            <b-form-input id="contact_no" type="text" v-model="user.phone"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-sm-6">
                            <label for="email">{{ $t('customer.email') }}:</label>
                            <b-form-input id="email" type="text" v-model="user.email"></b-form-input>
                          </b-form-group>

                          <b-form-group class="col-sm-12 mt-4" :label="$t('customer.password')">
                            <small>{{ $t('customer_.password_details') }}</small>
                            <b-form-input id="password" type="password" v-model="user.password"></b-form-input>
                          </b-form-group>

                        </b-row>
                        <b-button type="submit" variant="primary" class="mr-2" >{{ $t('customer.save') }}</b-button>
                      </b-form>
                    </template>
                  </iq-card>
                </tab-content-item>
              </tab-content>
            </div>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'
import profile from '@/assets/images/user/customer_placeholder.png'

export default {
  name: 'ProfileEdit',
  mounted () {
    core.index()
    this.getCustomerDetails()
  },
  data () {
    return {
      apiToken: localStorage.getItem('customer_api_token') || '',
      user: {
        name: '',
        email: '',
        password: '',
        phone: '',
        logo: require('../../assets/images/user/customer_placeholder.png')
      }
    }
  },
  methods: {
    getCustomerDetails () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.get(API.API_CUSTOMER_GET_CUSTOMER)
        .then((response) => {
          if (response.data.status === 'success') {
            const thisUser = JSON.parse(response.data.user)
            this.user = thisUser
            if (thisUser.logo !== '') {
              this.user.logo = thisUser.logo
            } else {
              this.user.logo = profile
            }
          }
        }).catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    savePersonalInformation (item) {
      const formData = new FormData()
      if (this.$refs.file.files[0]) {
        formData.append('logo', this.$refs.file.files[0])
      }
      formData.append('name', item.name)
      formData.append('phone', item.phone)
      formData.append('email', item.email)
      formData.append('password', item.password || '')
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_CUSTOMER_ADD_CUSTOMER, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('customer.user_updated'))
            this.$emit('set_userdetails', response.data.name, response.data.logo)
          } else {
            core.showSnackbar('error', this.$t('customer_.missing_fields'))
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    previewImage: function (task) {
      const reader = new FileReader()
      reader.onload = (e) => {
        task.logo = e.target.result
      }
      reader.readAsDataURL(this.$refs.file.files[0])
      this.savePersonalInformation(task)
    }
  }
}
</script>
<style>
form > .form-group > div{
  width: 100%;
}
</style>
